<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家資料管理《店家》</li>
            <li class="breadcrumb-item active" aria-current="page">店家資料維護</li>
            <li class="breadcrumb-item active" aria-current="page">店家商品資訊</li>
            <li class="breadcrumb-item active" aria-current="page">店家商品資訊編輯</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" :validation-schema="schema" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form">
                <div class="mb-3">
                    <label for="store_code" class="form-label">店家編號</label>
                    <input type="text" class="form-control" id="store_code" v-model="product_data.store_code" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="tax_id" class="form-label">統一編號</label>
                    <input type="text" class="form-control" id="tax_id" v-model="product_data.tax_id" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="store_name" class="form-label">店家名稱</label>
                    <input type="text" class="form-control" id="store_name" v-model="product_data.store_name" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="product_name" class="form-label"><span class="required">*</span>商品名稱</label>
                    <Field name="product_name" v-slot="{ meta, field }" v-model="product_data.name">
                        <input
                            id="product_name"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="product_name" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="product_name_en" class="form-label">商品名稱（英文）</label>
                    <Field name="product_name_en" v-slot="{ meta, field }" v-model="product_data.name_en">
                        <input
                            id="product_name_en"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                        />
                    </Field>
                    <ErrorMessage name="product_name_en" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="product_introduce" class="form-label"><span class="required">*</span>商品簡介</label>
                    <Field name="product_introduce" v-slot="{ meta, field }" v-model="product_data.product_introduce">
                        <textarea
                            id="product_introduce"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            rows="8"
                        />
                    </Field>
                    <ErrorMessage name="product_introduce" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="product_introduce_en" class="form-label">商品簡介（英文）</label>
                    <textarea class="form-control" id="product_introduce_en" rows="8" v-model="product_data.product_introduce_en"></textarea>
                </div>
                <div class="mb-3">
                    <label class="form-label"><span class="required">*</span>商品啟用狀態</label>
                     <div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="product_data.status">
                                <input
                                    id="status_radio_1"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="1"
                                    :checked="product_data.status === 1"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_1">啟用</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="product_data.status">
                                <input
                                    id="status_radio_2"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="0"
                                    :checked="product_data.status === 0"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_2">停用</label>
                        </div>
                    </div>
                    <ErrorMessage name="status_options" class="radio-invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="image" class="form-label"><span class="required">*</span>上傳商品圖片</label>
                    <input type="file" class="form-control" id="image" @change="file_selected" accept=".jpg, .jpeg, .png" ref="image_input">
                    <div id="image_help" class="form-text">檔案大小不可超過 5 MB，檔案格式：png、jpeg、jpg</div>
                </div>
                <div class="mb-3">
                    <img v-if="product_data.image && image == null" :src="product_data.image" style="width: 100%"/> 
                    <img v-if="image" :src="image" style="width: 100%"/> 
                </div>
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
                <div class="mb-3">
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import {
    get_store_product_detail,
    save_store_product_detail,
} from '../../api/store_product';
import { mapActions, mapGetters } from 'vuex';
import { get_store_basic_info } from '../../api/store';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { store_product_rules } from '../../rules/store/store_product';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            product_data: {
                store_code: "",
                tax_id: "",
                store_name: "",
                name: "",
                name_en: "",
                product_introduce: "",
                product_introduce_en: "",
                status: "",
            },
            button_disabled: false,
            image: null,
            upload_image: null,
            can_go_back: false,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_store_product_detail({store_id: this.$route.params.store_id, store_product_id: this.$route.params.id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'StoreProductList' });
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'StoreProductList' });
                } else {
                    this.product_data = response.data.response_data;
                }
            })
        },
        get_store_info() {
            get_store_basic_info({store_id: this.$route.params.store_id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'StoreList' });
                } else {
                    this.product_data.store_id = response.data.response_data.store_id;
                    this.product_data.store_code = response.data.response_data.store_code;
                    this.product_data.tax_id = response.data.response_data.tax_id;
                    this.product_data.store_name = response.data.response_data.store_name;
                }
            })
        },
        save() {
            this.button_disabled = true;
            let submit_product_data = JSON.parse(JSON.stringify(this.product_data));
            submit_product_data.image = null;
            let form_data = new FormData();
            form_data.append('product_data', JSON.stringify(submit_product_data));
            if (this.image !== null) {
                form_data.append('upload_image', this.upload_image, this.upload_image.name);
            }
            save_store_product_detail(form_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1005") {
                    this.button_disabled = false;
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            cancelButton: 'm-2 btn btn-danger'
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '錯誤訊息',
                        text: '商品資訊同時啟用數量超過 5 筆！',
                        icon: 'error',
                        cancelButtonText: '關閉',
                        showCancelButton: true,
                        showConfirmButton: false,
                        showCloseButton: true,
                        allowOutsideClick: false,
                    })
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'StoreProductList' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        file_selected(e) {
            if (e.target.files.length != 0) {
                if ((e.target.files.item(0).size / 1024 / 1024).toFixed(2) > 5) {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            cancelButton: 'm-2 btn btn-danger'
                        },
                        buttonsStyling: false
                    })

                    swalWithBootstrapButtons.fire({
                        title: '錯誤訊息',
                        text: '檔案大小超過 5 MB 限制！',
                        icon: 'error',
                        cancelButtonText: '關閉',
                        showCancelButton: true,
                        showConfirmButton: false,
                        showCloseButton: true,
                        allowOutsideClick: false,
                    });
                    this.$refs.image_input.value = null;
                    this.image = null;
                    this.upload_image = null;
                } else {
                    const file = e.target.files.item(0);
                    const reader = new FileReader();
                    reader.addEventListener('load', this.image_loaded);
                    reader.readAsDataURL(file);
                    this.upload_image = e.target.files[0];
                }
            } else {
                this.$refs.image_input.value = null;
                this.image = null;
                this.upload_image = null;
            }
        },
        image_loaded(e) {
            this.image = e.target.result;
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "StoreProductList"});
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.id !== undefined) {
            this.search();
        } else {
            this.get_store_info()
        }
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
        schema() {
           return store_product_rules;
        },
    }
};
</script>
